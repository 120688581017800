const mobileMenu = document.querySelector('.mobileMenu');
const mobileNav = document.querySelector('.nav-view');
const toggleMobile = document.querySelector('.toggle-menu');
const submenuElements = document.querySelectorAll('li.submenu');


const searchToggle = document.querySelector('.icon-search');
const searchBar = document.querySelector('.search');
const searchForm = document.querySelector('.main-navigation-search');



mobileMenu.addEventListener('click', function(){
    if (mobileMenu.classList.contains('active')){
        mobileMenu.classList.remove('active');
        mobileNav.classList.remove('active');
        toggleMobile.classList.remove('active');
    } else {
        mobileMenu.classList.add('active');
        mobileNav.classList.add('active');
        toggleMobile.classList.add('active')
        searchBar.classList.remove('active');
    }
});



searchToggle.addEventListener('click', function(){
    if(searchBar.classList.contains('active')){
        searchBar.classList.remove('active');
    } else {
        searchBar.classList.add('active');
        mobileMenu.classList.remove('active');
        mobileNav.classList.remove('active');
        toggleMobile.classList.remove('active');
    }
});









submenuElements.forEach(submenuElement => {
  submenuElement.addEventListener('click', (event) => {
    event.stopPropagation();
    submenuElement.classList.toggle('active'); 
  });
});


document.addEventListener('click', (event) => {
  const clickedElement = event.target;
  if (!clickedElement.closest('.submenu')) {
    submenuElements.forEach(submenuElement => {
      submenuElement.classList.remove('active');
    });
  }
});



