
function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) == 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}



try {

const closeHint = document.querySelector('.button.allow');
const hint = document.querySelector('.ce_rsce_altershinweis');

closeHint.addEventListener('click', function() {
    hint.style.display = 'none';
    setCookie('altershinweis', 'true', 365); // Setze das Cookie für 365 Tage
});

function checkHint() {
    var getCookieValue = getCookie('altershinweis');

    if (getCookieValue === 'true') {
        hint.style.display = 'none';
    } else {
        hint.style.display = 'block';
    }
}


checkHint();

} catch {

}