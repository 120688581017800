
const navItemLvl1 = document.querySelectorAll('.mod_navigation li');
const topNav = document.querySelectorAll('.top nav');

navItemLvl1.forEach(navItem => {
    navItem.addEventListener('mouseover', () => {
        const ul = navItem.querySelector('ul');
        const ulHeight = window.getComputedStyle(ul).height;

        const liCount = ul.querySelectorAll('li').length;
        const adjustedHeight = parseFloat(ulHeight) + (liCount * 10) + 'px';

        topNav.forEach(topNavElement => {
            topNavElement.style.setProperty('--nav-height', adjustedHeight);
        });
    });
});





