


var header = document.querySelector('header');


window.addEventListener("scroll", function () {
  checkScrollPosition();
}, {passive: true});

function checkScrollPosition(){
  if(window.scrollY > 250){
    header.classList.add('scrolled');
  }else if (window.scrollY < 100) {
    header.classList.remove('scrolled');
  }
}
checkScrollPosition();
