

if(document.querySelector(".ce_rsce_eventlist")){
    document.querySelectorAll(".layout_list_template").forEach(element => {
        element.querySelector(".top").addEventListener("click", () => {
            var bottom = element.querySelector(".bottom");
            if (!element.classList.contains("active")) {
                element.classList.add("active");
                expandSection(bottom);
            } else {
                element.classList.remove("active");
                collapseSection(bottom);
            }
        });
        element.querySelector(".close-button").addEventListener("click", () => {
            var bottom = element.querySelector(".bottom");
            element.classList.remove("active");
            collapseSection(bottom);
        });
    });
}

if(document.querySelector(".ce_rsce_akkordeon")){
    document.querySelectorAll(".akkordeon").forEach(element => {
        element.querySelector(".akkordeon-inner").addEventListener("click", () => {
            var bottom = element.querySelector(".akkordeon-details");
            if (!element.classList.contains("active")) {
                element.classList.add("active");
                expandSection(bottom);
            } else {
                element.classList.remove("active");
                collapseSection(bottom);
            }
        });
       
    });
}

function collapseSection(element) {
    var sectionHeight = element.scrollHeight;
    var elementTransition = element.style.transition;
    element.style.transition = '';
    requestAnimationFrame(function () {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;
        requestAnimationFrame(function () {
            element.style.height = 0 + 'px';
        });
    });
    element.setAttribute('data-collapsed', 'true');
}

function expandSection(element) {
    var sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + 'px';
    element.addEventListener("transitionend", transitionEnd);
    element.setAttribute('data-collapsed', 'false');
}

function transitionEnd(event){
    if(event.target.classList.contains("active")){
        event.target.style.height = null;
    }
    event.target.removeEventListener("transitionend", transitionEnd);
}

