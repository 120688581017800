if (window.innerWidth > 1000) {
    const headline = document.querySelector('h1');

    if (headline) {
        const text = headline.innerText;
        const lastSpaceIndex = text.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
            const newText =
                text.substring(0, lastSpaceIndex) + '&nbsp;' + text.substring(lastSpaceIndex + 1);
            headline.innerHTML = newText;
        }
    }
}




const pElements = document.querySelectorAll('.ticker-container p');
pElements.forEach(p => {
  const originalText = p.textContent;
  p.textContent = '+++ ' + originalText + ' +++';
});
