
const bierDetail = document.querySelector('.ce_rsce_bierdetail');
const bierDetailSlider = document.querySelectorAll('.slide');


setInterval(function(){
    if(bierDetail != null){
        if (bierDetail.classList.contains('aos-animate')){
            runSlide();
        } else {
            returnSlide();
        }
    } else {
        return;
    } 
}, 1000 )



function runSlide(){
    bierDetailSlider.forEach(slide => {
        const slideLeft = slide.getAttribute('data-left');
        slide.style.left = slideLeft;
    });
}

function returnSlide(){
    bierDetailSlider.forEach(slide => {
        slide.style.left = '0';
    });
}


