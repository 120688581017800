try {
    
    const navBeerActive = document.querySelectorAll('.left .mod_navigation li ul li .active');
    const navBeer = document.querySelectorAll('.left .mod_navigation li:nth-child(2) ul li');

    const prevBeerButton = document.querySelector('.prevBeer');
    const nextBeerButton = document.querySelector('.nextBeer');

    let navArray = [];
    let navArrayUrl = [];
    let actBeer = navBeerActive[0].innerText;



    function createArray(){
        navBeer.forEach(function(beer){
            // Überseiten nicht in die Array
            if (beer.innerHTML.split('/').length < 7){
                navArray.push(beer.innerText);
                navArrayUrl.push(beer.innerHTML);
            }
        })
    }


    function findNexBeer(actBeer){
        createArray();
        let nextBeerIndex = navArray.indexOf(actBeer);
        let nextBeerUrl
        if (nextBeerIndex + 1 > navArrayUrl.length-2){
            nextBeerUrl = navArrayUrl[0];
        } else {
            nextBeerUrl = navArrayUrl[nextBeerIndex +1];
        }

        nextBeerUrl = nextBeerUrl.split('href="')[1];
        nextBeerUrl = nextBeerUrl.split('"')[0];
    
        return nextBeerUrl;
    
    }

    function findPrevBeer(actbeer){
        createArray();
        let prevBeerIndex = navArray.indexOf(actBeer);
        let prevBeerUrl
        if (prevBeerIndex - 1 < 0){
            prevBeerUrl = navArrayUrl[navArrayUrl.length-2];
        } else {
            prevBeerUrl = navArrayUrl[prevBeerIndex -1];
        }

        prevBeerUrl = prevBeerUrl.split('href="')[1];
        prevBeerUrl = prevBeerUrl.split('"')[0];

        return prevBeerUrl;
    }






    prevBeerButton.addEventListener('click', function(){
        window.open(findPrevBeer(actBeer),"_self");
    })

    nextBeerButton.addEventListener('click', function(){
        window.open(findNexBeer(actBeer),"_self");
    
    })

} catch {

}